import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { ConfigService } from '@src/config/ConfigService';
import { AuthLoginFormValidationSchema } from '@pages/Auth/Login/login.schema';
import { ILoginAxiosRequest, ILoginFormData } from '@src/types/auth';
import { IEmailProtectedRouteState } from '@src/router/guards/EmailProtectedGuard/types';
import { IReCaptchaActionType } from '@components/ReCaptchaBox/types';
import { useLogin } from '@src/api/hooks/auth/useLogin';
import { useValidatedForm } from '@src/hooks/useValidatedForm';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';

import { AuthLayout } from '@pages/Auth/AuthLayout/AuthLayout';
import ValidatedFormInput from '@components/ValidatedFormInput/ValidatedFormInput';
import BaseButton from '@ui/BaseButton/BaseButton';
import Spinner from '@ui/Spinner/Spinner';
import ReCaptchaBox from '@components/ReCaptchaBox/ReCaptchaBox';
import { trimmedEmail } from '@src/utilities/helpers';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';
import { useLocationSearchParams } from '@src/hooks/useLocationSearchParams';

import {
	StyledAuthAction,
	StyledAuthActions,
	StyledAuthItem,
	StyledAuthLink,
	StyledAuthNote,
	StyledAuthTitle,
} from '@pages/Auth/styles';

export const Login = () => {
	const location = useLocation();
	const searchParams = useLocationSearchParams();
	const { client } = useRoutingContext();
	const { t } = useTranslation();
	const { login, isLoading } = useLogin();
	const { withQueryParams, toExternalRedirectUrl } = useNavigateWithQueryParamsPersistence();
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('login'));

	const state = location.state as IEmailProtectedRouteState;
	const email = state?.email || searchParams?.email;

	const { ValidatedFormProvider, methods } = useValidatedForm<ILoginFormData>({
		schema: AuthLoginFormValidationSchema,
		options: {
			defaultValues: {
				email: email || '',
				password: '',
				isValidRecaptcha: !ConfigService.isRecaptchaEnabled,
			},
		},
		handleFormSubmit: async data => {
			const dataToSend: ILoginAxiosRequest = {
				username: trimmedEmail(data.email),
				password: data.password,
			};

			if (!!searchParams.requestedBookingId) {
				dataToSend['deepLinkReference'] = searchParams.requestedBookingId;
			}

			await login(dataToSend, {
				onSuccess: () => {
					dataLayer.event('login');
					toExternalRedirectUrl();
				},
			});
		},
	});

	const validRecaptcha = methods.getValues('isValidRecaptcha');

	return (
		<AuthLayout
			header={
				<StyledAuthTitle size="small" textAlign="center">
					{t('auth.signIn.title')}
				</StyledAuthTitle>
			}
		>
			<ValidatedFormProvider>
				<StyledAuthItem>
					<ValidatedFormInput disabled={!!email} localeGroup="auth" name="email" icon="envelope" />
				</StyledAuthItem>

				<StyledAuthItem>
					<ValidatedFormInput localeGroup="auth" name="password" icon="lock" type="password" />
				</StyledAuthItem>

				<StyledAuthActions>
					{!validRecaptcha && (
						<ReCaptchaBox widgetId="item2" action={IReCaptchaActionType.login} name="isValidRecaptcha" />
					)}

					<StyledAuthAction>
						<BaseButton disabled={isLoading} isLoading={isLoading} type="submit">
							{t('auth.signIn.buttonText')}
						</BaseButton>
					</StyledAuthAction>
				</StyledAuthActions>

				<StyledAuthNote>
					{t('auth.signIn.note')}{' '}
					<StyledAuthLink to={withQueryParams(client.forgotPassword())}>{t('auth.signIn.link')}</StyledAuthLink>
				</StyledAuthNote>
			</ValidatedFormProvider>
		</AuthLayout>
	);
};
