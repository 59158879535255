import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthForgotPasswordFormValidationSchema } from '@pages/Auth/ForgotPassword/forgotPassword.schema';
import { IForgotPasswordFormData } from '@src/types/auth';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { useValidatedForm } from '@src/hooks/useValidatedForm';
import { useForgotPassword } from '@src/api/hooks/auth/useForgotPassword';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';

import { AuthLayout } from '@pages/Auth/AuthLayout/AuthLayout';
import BaseButton from '@ui/BaseButton/BaseButton';
import ValidatedFormInput from '@components/ValidatedFormInput/ValidatedFormInput';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';
import { trimmedEmail } from '@src/utilities/helpers';

import {
	StyledAuthAction,
	StyledAuthActions,
	StyledAuthEntry,
	StyledAuthItem,
	StyledAuthTitle,
} from '@pages/Auth/styles';

export const ForgotPassword = () => {
	const { t } = useTranslation();
	const { client } = useRoutingContext();
	const { isLoading, forgotPassword } = useForgotPassword();
	const { navigate } = useNavigateWithQueryParamsPersistence();
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('password:forgot'));

	const { ValidatedFormProvider } = useValidatedForm<IForgotPasswordFormData>({
		schema: AuthForgotPasswordFormValidationSchema,
		options: {
			defaultValues: {
				email: '',
			},
		},
		handleFormSubmit: async data => {
			await forgotPassword(data, {
				onSuccess: () => {
					dataLayer.event('forgotPassword');

					navigate(client.resetPassword(), {
						state: {
							email: trimmedEmail(data.email),
						},
					});
				},
			});
		},
	});

	return (
		<AuthLayout
			header={
				<Fragment>
					<StyledAuthTitle textAlign="center">{t('auth.forgotPassword.title')}</StyledAuthTitle>

					<StyledAuthEntry>{t('auth.forgotPassword.entry')}</StyledAuthEntry>
				</Fragment>
			}
		>
			<ValidatedFormProvider>
				<StyledAuthItem>
					<ValidatedFormInput localeGroup="auth" name="email" icon="envelope" />
				</StyledAuthItem>

				<StyledAuthActions>
					<StyledAuthAction>
						<BaseButton disabled={isLoading} isLoading={isLoading} type="submit">
							{t('auth.resetMyPassword')}
						</BaseButton>
					</StyledAuthAction>
				</StyledAuthActions>
			</ValidatedFormProvider>
		</AuthLayout>
	);
};
